.main-footer {
    color: white;
    background-color: #393636;
    padding-top: 3em;
    position: relative;
    bottom: 0;
    width: 100%;
    font-size: 15px;
    font-weight: lighter;
    margin-top: auto;
   
  }
  .Insta{
    text-decoration: none;
  }

  .list-unstyled{
    font-size: 15px;
  }
  .col h4{
    font-weight:normal;
  }
  .container{
    display:inline-block;
    margin-left: 13%;
    
  }

  @media screen  and (max-width:768px){
    .main-footer {
      bottom: 0px;
      text-align: center;
      font-weight: lighter;

    }
    .col{
      display: grid;
    }
    li{
      display: grid;
    }
    
  .list-unstyled{
    font-size: 10px;
  }
  .container{
    margin: 0 ;
  }

  }