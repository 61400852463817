.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f8f9fa;
    
  }
  .content-wrap {
    flex: 1;
  }

  html {
    scroll-behavior:smooth ;
  }