@media screen  and (max-width:768px){
.nav{
    display: grid;
    

}
.navbar-toggler{
    margin-right: -1%;
    
    
}
.nav-item{
    margin-left: 38%;
    justify-content: center;
  
}

.navbar-brand p{
    margin-left: -15px;
}


}