.slideWrap{
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color:rgba(0, 0, 0, 0.863);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition-delay: 1s;
}

.slideWrap .btnclose ,.slideWrap .btnright , .slideWrap .btnleft{
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: rgb(255, 255, 255);
    z-index: 999;  
} 
.btnclose:hover, .btnleft:hover, .btnright:hover{
    opacity: 1;
}

.slideWrap .btnclose{
    top: 40px;
    right: 40px;  
}
.slideWrap .btnright{
    top: 50%;
    transform: translateY(-50%);
    right: 40px; 
}
.slideWrap .btnleft{
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
}

.fullScreenImage{
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    pointer-events: none;
}
.container{
    display: grid;
    font-size: small;
    justify-content: center;
    align-items: center;
}


@media screen  and (max-width:768px){
    .fullScreenImage img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 20px;
        pointer-events: none;
    }
    .container{
        display: grid;
        font-size: 10px;
        justify-content: center;
        align-items: center;
        
    }
    .col-9{
        width: 95%;
    }
}







