.about-me-container {
    display: flex;
    align-items: center;
    padding-inline: 10%;
    
  }
  
  .portrait-container {
    flex: 1;
    margin-right: 12%;
    margin-bottom: 20px;
    margin-top: 15px;
  }
  
  .portrait-image {
    width: 80%;
    max-width: 500px;
    border-radius: 50%;
    margin-left: 50px;

  }
  
  .info-container {
    flex: 2;
    margin-top: 10px;
    font-size: 18px;
    padding: 3%;
  }

  @media screen  and (max-width:768px){
    .about-me-container {
      display: grid;
      align-items: center;
      text-align: center;
      padding: 10%;
    }
    .portrait-image {
      width: 49%;
      max-width: 500px;
      border-radius: 50%;
      margin-left: 50px;
  
    }
    

  }
