.containera{
    
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.image1{
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
    
    
}
.containerb{
    
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 800px;
    display: flex;
    padding-left: 8%;
    
    
}
.image2{
    max-width: 50%;
    max-height: 100%;
    object-fit: scale-down;
    margin: 10px;
}
.image3{
    max-width: 30%;
   padding-top: 230px;
    object-fit: scale-down;
    margin: 84px;
    margin-top: -169px;

}


.containerd{
    text-align: center;
    
    
}

.containerd h1 {
    font-size: 30px;
    margin-top: 50px;
    font-weight: normal;
}
.span {
    text-decoration: underline;
    color: black;
}

.containere{
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image5{
    width: 500px;
    height: 300px;
    margin: 25px;
    object-fit: scale-down;
}

@media screen  and (max-width:768px){
    
    .containera{
        width: fit-content;
        margin-top: -200px;
        margin-bottom: -200px
    };
    .containerb{
        width: fit-content;
    }
    .image2{
        max-width: 55%;
        max-height: 100%;
        object-fit: scale-down;
        margin-top: -300px;
        margin-left: -25px;   
    }
    .image3{
        max-width: 45%;
        max-height: 100%;
        object-fit: scale-down;
        padding-top: 60px;
        margin-top: -330px;
        margin-left: 10px;
    }
   
    .containerd{
        width: fit-content;
        object-fit: scale-down;
        margin-top: -580px;
        margin-left: 15%;
    }
    .containerd h1{
        font-size: 20px;
    }
    
    .containere{
        width: 100px;
        margin-bottom: 0;
      

    }
    .image5{
    max-width: 80%;
    padding: 0px;
    object-fit: cover;
    }
}