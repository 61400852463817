/* Contact.css */
.contact-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-weight: light;
  }
  
  .left {
    flex: 1;
    margin: auto;
    padding-left: 10px;
  }
  
  .left h1 {
    font-size: 44px;
    margin-bottom: 30px;
    font-weight: normal;
    margin-left: 70px;
  }
  
  .left p {
    font-size: 16px;
    margin-bottom: 20px;
    margin-left: 70px;
    text-decoration: none;

  }
  .social-links {
    display: flex;
    margin-left: 70px;
  }
  .social-links a {
    
    margin-bottom: 10px;
    margin: 5px;
  }
  
  .right {
    flex: 1;
  }
  
  .form-group {
    margin-bottom: 20px;
    display: flex;
    margin: 20px;
  
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
  }
  
  button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 30px;
    transition: 1000ms;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
    
  }

  @media screen  and (max-width:768px){

    
    .left {
     margin-left: -20%;
      text-align: center;
    }
    .social-links{
      justify-content: center;
      align-items: center;
    }
  }
  